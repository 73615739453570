import React, { useState, useEffect, useRef } from "react";

const localStorageLockId = "campfire-locked";

export default function useOneTimeLock() {
  const memoryDate =
    process.browser &&
    localStorage.getItem(localStorageLockId) &&
    new Date(localStorage.getItem(localStorageLockId));

  const t = new Date();

  const [isLocked] = useState(memoryDate ? t <= memoryDate : false);

  function setLock() {
    localStorage.setItem(
      localStorageLockId,
      new Date(t.getFullYear(), t.getMonth(), t.getDate() + 1).toISOString()
    );
  }

  return {
    isLocked,
    setLock,
  };
}
