import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// import classNames from 'classnames/bind';
import styles from "./CampfireEntry.scss";
import InnerHeight from 'ios-inner-height'

import window from 'global'
import { gsap, Expo, TimelineMax, Linear } from "gsap";
// const cx = classNames.bind(styles);';


class CampfireEntry extends PureComponent {
  componentDidMount() {

    const { handleConfirm, isLocked } = this.props;

    var home_logo = document.querySelector('.home_logo');
    var campfire_entry_cover = document.querySelector('.campfire_entey_cover');
    var unlock_campfire_entry_cover = document.querySelector('.campfire_entey_cover.unlock');
    var entry_btn = document.querySelector('.entry_btn');

    campfire_entry_cover.style.height = window.innerHeight + 'px';
    home_logo.classList.add('video_playing');
    document.body.classList.add('campfire_playing');
    

    if ( isLocked ){
      document.querySelector('.entry_btn_text').innerHTML = '明天見';
      campfire_entry_cover.classList.add('locked');
    } else {
      document.querySelector('.entry_btn_text').innerHTML = '演出開始';
      campfire_entry_cover.classList.add('unlock');
      entry_btn.addEventListener('mouseover', function(){
        gsap.fromTo(campfire_entry_cover, {
          opacity: 1,
        }, {
          opacity: 0,
          duration: 1,
          ease: Expo.easeOut,
          onComplete: function(){
            handleConfirm();
          }
        });
      });
    }

    document.querySelector('.home_logo').addEventListener('click', function(){
      document.body.classList.remove('campfire_playing');
      this.classList.remove('video_playing');
    });

  }

  componentWillUnmount() {
  }

  render() {
    // console.log(this.props.handleConfirm);

    const { handleConfirm, isLocked } = this.props;
    
    return (
      // <div className={styles.CampfireEntry}>
      //   已鎖定：{`${isLocked}`}
      //   <button
      //     style={{ position: "absolute", left: "50%", top: "50%" }}
      //     onClick={handleConfirm}
      //   >
      //     確認
      //   </button>
      // </div>
      <div className="campfire_entey_cover">
        <div className="note">
          <p>請注意，每台裝置「每日只有一次」觀賞演出的機會，一旦關上視窗後，該裝置當天就無法再度進入本演出。</p>
        </div>
        <div className="entry_btn" onClick={handleConfirm}><div className="entry_btn_text">演出開始</div> <div className="entry_trigger"></div></div>
      </div>
    );
  }
}

CampfireEntry.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
};

CampfireEntry.defaultProps = {};

export default CampfireEntry;
