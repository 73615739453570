import React, { useState, useCallback, useEffect } from "react";
import CountDownBlock from "../../../CountDownBlock";

import Campfire from "./Campfire.jsx";
import CampfireEntry from "./components/CampfireEntry";
import useOneTimeLock from "../../../../hooks/useOneTimeLock.js";

const CampfirePage = () => {
  const { isLocked, setLock } = useOneTimeLock();
  const [isConfirm, setIsConfirm] = useState(false);

  const handleConfirm = () => {
    setIsConfirm(true);
    setLock();
  };

  return !isConfirm || isLocked ? (
    <CampfireEntry handleConfirm={handleConfirm} isLocked={isLocked} />
    // <Campfire />
  ) : (
    <Campfire />
  );
};

const Test = () => {
  return <div>123</div>;
};

export default () => {
  return (

    // <Campfire />
    <CountDownBlock
      blockType="countDown"
      targetDate={new Date(2020, 3, 27)}
      Component={CampfirePage}
    >
      {/*月份需要減一記得！*/}
      {/* <Campfire /> */}
    </CountDownBlock>
  );
};
